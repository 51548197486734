import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Menu, MenuItem, ListItemText, Divider, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { apiService2 } from '../features/apiService';
import { myConfig } from '../settings';

const NotificationDropdown = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        apiService2('GET', myConfig.getNotificationEP)
            .then(response => {
                console.log('getNotificationEP', response);
                setNotifications(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [refresh]);

    const unreadCount = notifications.filter(n => !n.read).length;

    return (
        <div>
            <IconButton
                color="inherit"
                onClick={handleOpen}
                aria-controls="notification-menu"
                sx={{
                    marginTop: 1
                }}
                aria-haspopup="true">
                <Badge badgeContent={unreadCount} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>

            <Menu
                id="notification-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: 300,
                    }
                }}
            >
                <Typography variant="h6" sx={{ p: 2 }}>
                    Notifications
                </Typography>
                <Divider />
                {notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <MenuItem key={notification._id} onClick={handleClose}>
                            <ListItemText
                                primary={notification.title}
                                secondary={notification.description}
                            />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem onClick={handleClose}>
                        <ListItemText primary="No new notifications" />
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default NotificationDropdown;
