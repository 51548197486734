import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { myConfig } from "../../settings";
import { assetModel } from "../../schema/schema.js";
import { apiService, apiService2 } from '../../features/apiService';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { formCreator } from "../../features/helpers/formhelper";
import { getAssets, getConfig } from '../../features/helpers/genhelper';

// API End Point
const endPoint = myConfig.updateAssetEP;
let objindex = 0;
let fakecount = 0;

const EditAsset = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionCompleted2, setSubmitionCompleted2] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [formFields, setFormFields] = useState(assetModel);
  const [status, setStatus] = useState('');
  const { user } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});
  const [modAssetList, setAssets] = useState([]);
  const [newSpec, setNewSepc] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [unitConfig, setUnitConfig] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateAsset, setUpdateAsset] = useState('');
  const [assetList, setAssetList] = useState([]);
  const [errorMessages, setErrorMessages] = useState('Check your entries..');
  const asset = props.open.asset;
  if (props.open.state & !open) {
    setOpen(true);
    setIsUpdate(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    getInitialValues(0);
  }

  function getInitialValues(i) {
    let ivalues = {};
    var currentState;
    const asset = props.open.asset;
    currentState = asset['state'];
    formFields.forEach((value) => {
      if (value['field'] == 'approval' || value['field'] == 'reset' || value['field'] == 'iiotenabled') {
        ivalues[value['field']] = asset[value['field']] == true ? 'Yes' : 'No';
      } else if (!value['field'].includes('Url')) {
        ivalues[value['field']] = asset[value['field']];
      }
    });
    asset.specifications.forEach(res => {
      ivalues[res.fieldname] = res.values;
    })
    ++fakecount;
    assetModel.forEach((value) => {
      if (value.field == 'state') {
        if (currentState == 'Breakdown') {
          value['venum'] = ["Breakdown", "Suspended", "Decommissioned"];
        } else if (currentState == 'Faulty') {
          value['venum'] = ["Faulty", "Suspended", "Decommissioned"];
        } else if (currentState == 'Suspended') {
          value['venum'] = ["Suspended", "Operational", "Decommissioned"];
        } else {
          value['venum'] = ["Operational", "Suspended", "Decommissioned"]
        }
      }
    });
    if (i == 1) {
      handleAddSpec();
    }
    setFormFields([...assetModel]);
    setInitialValues(ivalues);

  }

  //Get Assets
  useEffect(() => {
    getAssets(user).then(resp => { setAssetList(resp[0]); })
    const asset = props.open.asset;
    let assets = assetList.filter(x => x.section == asset['section']);
    assets = assets.map(x => x.assetid);
    setAssets(assets);
  }, [open])
  const [familyList, setFamilyList] = useState([]);
  useEffect(() => {
    apiService2('GET', myConfig.getAssetfamily, {},)
      .then(response => {
        setFamilyList(response.data);
        let fields = formFields.filter(x => !x.dynamicfiled);
        let family = familyList.find(x => x._id == asset['assetfamilyid']);
        var newSpecs = [];
        if (family?.assetfamilyList.length > 0) {
          family?.assetfamilyList.forEach(res => {
            if (asset.specifications.find(x => x.fieldname == res.fieldname)) {
              fields.push({ field: res.fieldname, type: String, required: false, label: res.fieldname, dynamicfiled: true })
            } else {
              newSpecs.push({ fieldname: res.fieldname, type: String, required: false, label: res.fieldname, dynamicfiled: true })
            }
          })
        }
        setFormFields([...fields])
        setNewSepc([...newSpecs]);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  let modFamilyList = [];
  for (const fam of familyList) {
    modFamilyList.push(fam.name);
  }

  // Get Facility list
  const [facilityList, setFacilityList] = useState([]);
  useEffect(() => {
    apiService2('POST', myConfig.getFacilitiesFilterEP, {},)
      .then(response => {
        setFacilityList(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  let modSectionList = facilityList.filter(x => x.type === 'Section').map(fac => fac.name);

  let modSubSectionList = facilityList.filter(x => x.parent === asset['section'] && x.type === 'Sub Section').map(fac => fac.name);

  // Get User list
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getUsersEP)
      .then(response => {
        setUserList(response.data)
        if (response.data.length == 0) {
          toast.error("User list Empty...");
        }
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  let modUserList = [];
  for (const userItem of userList) {
    modUserList.push(userItem.lastname + ", " + userItem.firstname + ", " + userItem.email);
  }

  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    props.open.setfn();
    objindex = 0;
  }

  function handleAddSpec() {
    let fields = formFields;
    newSpec.forEach(res => {
      fields.push({ field: res.fieldname, type: String, required: false, label: res.fieldname, dynamicfiled: true })
    })
    setFormFields([...fields])
  }

  // Handle retry when submit failed
  function handleRetry() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    //setFormFields(genFormFields);
    props.open.setfn();
    //objindex = 0;
  }
  // Check state transitioned
  function checkState(values) {
    if (values.state == "Breakdown") {
      toast.error("This asset state change not allowed: " + values.state);
      return false;
    }
    return true;
  }

  useEffect(() => {
    getConfig(user, 'Unit').then(resp => setUnitConfig(resp));
  }, [open])

  useEffect(() => {
    apiService2('GET', myConfig.getUnitEP)
      .then(response => {
        setUnitList(response.data)
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);


  let modUnitList = [];
  for (const fac of unitList.filter(x => x.unitType == 'Meter')) {
    modUnitList.push(fac.parameter);
  }

  const valueDB = { "parentassetid": modAssetList, "facility": facilityList, "section": modSectionList, "subsection": modSubSectionList, "family": modFamilyList, "unit": modUnitList };


  function handleSelect(key, value) {
    if (key == 'family') {
      let fields = formFields.filter(x => !x.dynamicfiled);
      let family = familyList.find(x => x.name == value);
      if (family?.assetfamilyList.length > 0) {
        family?.assetfamilyList.forEach(res => {
          fields.push({ field: res.fieldname, type: String, required: false, label: res.fieldname, dynamicfiled: true })
        })
      }
      setFormFields([...fields])
    } else if (key == 'section') {
      let values = [];
      values = valueDB['facility']
      values = values.filter(x => x.parent == value);
      let finalSubSection = [];
      values.forEach(res => {
        finalSubSection.push(res.name)
      })
      valueDB['subsection'] = finalSubSection;
      let asset = assetList.filter(x => x.section == value);
      asset = asset.map(x => x.assetid);
      valueDB['parentassetid'] = asset;
    } 
  }

  function updateAssetMethod() {

    apiService(endPoint, updateAsset)
      .then(resp => {
        setIsUpdate(false);
        if (resp.data['message']) {
          setErrorMessages(resp.data['message']);
          setSubmitionError(true);
          setSubmitionCompleted(true);
        } else {
          setSubmitionCompleted(true);
        }
      })
      .catch(error => {
        console.error(error);
        setSubmitionError(true);
        setSubmitionCompleted(true);
      })
  }

  function handleClose2() {
    setIsUpdate(false);
  }


  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
      >
        {!isSubmitionCompleted && !isUpdate && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Update Asset

            </DialogTitle>
            <DialogContent style={{ height: '680px' }}>
              {
                newSpec.length > 0 ?
                  <Button type="button" sx={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: colors.blueAccent[500], color: colors.primary[100] }} onClick={handleAddSpec}>
                    Add New Spec
                  </Button> : <></>
              }
              <Formik
                //enableReinitialize 
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  const lasset = props.open.asset;
                   let retobj = values;
                  if (!checkState(values)) { return; }
                  setSubmitting(true);
                  {
                    (() => {
                      for (let key in lasset) {
                        if (!retobj.hasOwnProperty(key)) {
                          retobj[key] = lasset[key];
                        }
                      }
                    })()
                  };
                  var fileds = formFields.filter(x => x.dynamicfiled);
                  var specifications = [];
                  fileds.forEach(res => {
                    specifications.push({ fieldname: res.field, values: values[res.field] })
                  })
                  retobj['specifications'] = specifications;
                  retobj['approval'] = values['approval'] == 'Yes' ? true : false;
                  retobj['reset'] = values['reset'] == 'Yes' ? true : false;
                  retobj['iiotenabled'] = values['iiotenabled'] == 'Yes' ? true : false;
                  retobj['tenantid'] = user.tenantid;
                 
                  var subsectionList = valueDB['subsection'];
                  retobj['subsection'] = subsectionList.length > 0 && subsectionList.filter(x => x == retobj['subsection']).length > 0 ? retobj['subsection'] : '';
                  setUpdateAsset(retobj);
                  setIsUpdate(true);
                }}
                // Need to expand the validation schema more later
                validationSchema={Yup.object().shape({})}>
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    resetForm,
                    setFieldValue
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box >
                        <Grid container spacing={2}>
                          {formCreator("Complex", 'edit', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue)}
                        </Grid>
                      </Box>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: getInitialValues(1) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks! Asset updated</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Thanks</DialogContentText>
              {isSubmitionCompleted2 && (
                <DialogContentText variant="h6">Asset Updated</DialogContentText>
              )}
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry! Asset Update Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">{errorMessages}</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                  Back
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isUpdate && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Update Asset</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Are you sure you want to update?</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={updateAssetMethod}>
                  Yes
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose2}>
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default EditAsset;