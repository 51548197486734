import { useTheme } from "@mui/material";
import { Chart } from "react-google-charts";
import { BarChart } from '@mui/x-charts/BarChart';
const BarCharts = ({ data }) => {
  const theme = useTheme();
  const options = {
    title: "",
    chartArea: { width: "100%" },
    legend: {
      position: "none",
    },
    backgroundColor: ['transparent'],
    colors: ['#8AD1C2',],
  };
  console.log(data);

  return (
    // <Chart
    //   chartType="Bar"
    //   width="100%"
    //   height="380px"
    //   data={data}
    //   options={options}
    // />
    //     <BarChart
    //   dataset={dataset}
    //   xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
    //   series={[
    //     { dataKey: 'london', label: 'London', valueFormatter },
    //     { dataKey: 'paris', label: 'Paris', valueFormatter },
    //     { dataKey: 'newYork', label: 'New York', valueFormatter },
    //     { dataKey: 'seoul', label: 'Seoul', valueFormatter },
    //   ]}
    //   {...chartSetting}
    // />
    <BarChart
      xAxis={[{ scaleType: 'band', data: ['Chart'] }]}
      slotProps={{ legend: { hidden: true } }}
      series={data.series}
      width={500}
      height={300}
    />
  );
};

export default BarCharts;
