import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Grid, Paper, Typography, useTheme } from "@mui/material";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { tokens } from "../../theme";
import { useSelector } from "react-redux";
import { apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { timeZoneConverter, timeZoneConverterDate } from "../../features/helpers/commonhelper";


const Profile = (props) => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [refresh, setRefresh] = React.useState(false);
  const [resource, setResource] = React.useState({});
  const [resourceList, setResourceList] = React.useState([]);

  function handleClose() {
    navigate('/');
  }

  React.useEffect(() => {
    apiService2('GET', myConfig.getLoginUserEP)
      .then(response => {
        var resource = response.data;
        setResource(resource);
        let fieldvalues = [];
        let id = 0;
        for (const [key, value] of Object.entries(resource)) {
          if (key != 'accesslist' && key != 'section' && key != 'notification'&& key != '_id'&& key != 'facility') {
            if (value != null && value != '') {
              if (key.includes("date") || key.includes("time") || key.includes("from") || key.includes("to") || key.includes("timestamp") || key.includes("createdOn")) {
                fieldvalues.push({ "id": id, "Asset Attribute": key, "Value": timeZoneConverter(value) });
              } else if (value == true || value == false) {
                fieldvalues.push({ "id": id, "Asset Attribute": key, "Value": value == true ? 'Yes' : 'No' });
              } else {
                fieldvalues.push({ "id": id, "Asset Attribute": key, "Value": value });
              }
              ++id;
            }
          }
        }
        setResourceList(fieldvalues);
      })
      .catch(error => {
        console.error(error);
      });
  }, [refresh]);

  function customToolbar() {
    return (
      <Box className="mb-1">
        <Button variant="contained" color="primary" startIcon={<ArrowBackIcon />} onClick={handleClose}>
          Back
        </Button>
      </Box>
    );
  }



  return (
    <Container component="main" maxWidth="lg">
      {customToolbar()}
      <Box
        sx={{
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          width: "100%",
        }}>
        <Box
          sx={{
            padding: '30px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
          }}
          component={Paper}>
          <Grid container spacing={4}>
            {
              resourceList.map((item, index) => {
                return (
                  <Grid item xs={3} key={index}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {item['Asset Attribute']?.toUpperCase()}
                    </Typography>
                    {item['Asset Attribute']?.toUpperCase() != 'FILEURL' ? <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                      {item['Value'] != null ? item['Value'] : "N/A"}
                    </Typography> : <img src={item['Value']} alt="Asset" width={50} />}
                  </Grid>
                );
              })
            }
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
export default Profile;