import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { apiService, apiService2 } from "../../features/apiService";
import { myConfig } from "../../settings";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
let fakecount = 0;

const InspectionForm = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(props.open.state);
    const { user } = useSelector((state) => state.auth);
    const [maxWidth, setMaxWith] = useState('lg');
    const [refresh, setRefresh] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isSubmition, setSubmition] = useState(false);
    const [isSubmitionError, setSubmitionError] = useState(false);
    const [inspectionList, setInspectionList] = useState([])
    const [woId, setWoId] = useState('s');
    const [assignee, setAssignee] = useState('');
    const [assetId, setAssetId] = useState('d');
    const [isVerify, setVerify] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [wostate, setWOState] = useState('');
    const [lastinspectionvalue, setLastInspectionValue] = useState('0');
    const [workorderList, setWorkorderList] = useState([]);
    const [errorMessages, setErrorMessages] = useState('');
    var date = new Date();
    let from = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    const [fromDate, setFromDate] = useState(dayjs(from));
    var enumList = ['Ok', 'Not-ok'];
    const workorder = props.open.workorder;
    if (props.open.state & !open) {
        const workorder = props.open.workorder;
        setOpen(true);
        setRefresh(!refresh);
        setMaxWith('lg')
        setInspectionList([])
        setVerify(false);
        setWoId(workorder['wonumber'])
        setAssetId(workorder['assetid'])
        setWOState('Green');
        setIsUpdate(false);
        setLastInspectionValue(0);
        setSubmition(false);
    }

    useEffect(() => {
        const workorder = props.open.workorder;
        if (workorder && workorder['assetid'] && workorder.state != 'Completed') {
            var data = { assetid: workorder['assetid'] }
            apiService2('POST', myConfig.getInspectionConfigEP, data)
                .then(response => {
                    console.log('response response');
                    var data = [];
                    data = response['data']['inspectionList'];
                    var aspect = workorder['inspection']['aspect']
                    data = data.filter(x => x.department == workorder['dept'] && aspect.includes(x.aspect))
                    var inspec = [];
                    data.forEach(res => {
                        inspec.push({
                            aspect: res.aspect, parameter: res.parameter, inspectionType: res.inspectionType, thresholdValue: res.green, measuredValue: res.measuredValue,
                            thresholdBeach: 'No', beachLevel: 'green', remarks: res.remarks, department: res.department, yellow: res.yellow, red: res.red,
                            greenCategory: res.greenCategory, yellowCategory: res.yellowCategory, redCategory: res.redCategory,
                            greenMin: res.greenMin, greenMax: res.greenMax, redMin: res.redMin, redMax: res.redMax, yellowMin: res.yellowMin, yellowMax: res.yellowMax,
                        })
                    })
                    setInspectionList([...inspec])
                })
                .catch(error => {
                    console.error(error);
                })
        } else if (workorder && workorder.inspectionList) {
            var inspec = [];
            workorder.inspectionList.forEach(res => {
                inspec.push({
                    aspect: res.aspect, parameter: res.parameter, inspectionType: res.inspectionType, thresholdValue: res.thresholdValue, measuredValue: res.measuredValue,
                    thresholdBeach: res.thresholdBeach, beachLevel: res.beachLevel, remarks: res.remarks, department: res.department,
                })
            })
            setInspectionList([...inspec])
        } else {
            setInspectionList([])
        }

    }, [refresh]);

    const [userList, setUserList] = useState([]);
    useEffect(() => {
        apiService(myConfig.getResource)
            .then(response => {
                if (response.data) {
                    var list = response.data;
                    const workorder = props.open.workorder;
                    list = list.filter(x => x.department == workorder.dept && x.section.includes(workorder.section));
                    list = list.map(x => x.email);
                    setUserList(list);
                    if(workorder.assignee != null && workorder.assignee != ''){
                        setAssignee(workorder.assignee);
                    }
                }

            }).catch(error => {
                console.error(error);
            })
    }, [open]);

    useEffect(() => {
        apiService(myConfig.getWorkOrderEP)
            .then(response => {
                var list = response.data;
                setWorkorderList(list);
            })
            .catch(error => {
                console.error(error);
            })
    }, [open]);


    function handleClose() {
        setOpen(false);
        setSubmitionError(false);
        setSubmitionCompleted(false);
        props.open.setfn();
    }

    function handleRetry() {
        setSubmitionError(false);
        setSubmitionCompleted(false);
    }


    function handleVerifiy() {
        var verify = true;
        var state = 'Green';
        inspectionList.forEach((res) => {
            if (res.measuredValue != null && res.measuredValue != '') {
                if (res.inspectionType === 'Measured') {
                    if (res.greenCategory === 'Greater than Equal') {
                        if (Number(res.measuredValue) < Number(res.thresholdValue)) {
                            if (Number(res.measuredValue) >= Number(res.yellowMax) && Number(res.measuredValue) < Number(res.yellowMin)) {
                                res.thresholdBeach = 'Yes';
                                res.beachLevel = 'yellow';
                                if (state != 'Red') {
                                    setWOState('Yellow');
                                    state = 'Yellow';
                                    setLastInspectionValue(res.measuredValue);
                                }
                            } else if (Number(res.measuredValue) >= Number(res.redMax) && Number(res.measuredValue) < Number(res.redMin)) {
                                res.thresholdBeach = 'Yes';
                                res.beachLevel = 'red';
                                setWOState('Red');
                                state = 'Red';
                                setLastInspectionValue(res.measuredValue);
                            } else {
                                verify = false;
                            }
                        } else {
                            res.thresholdBeach = 'No';
                            res.beachLevel = 'green';
                        }
                    } else {
                        if (Number(res.measuredValue) > Number(res.thresholdValue)) {
                            if (Number(res.measuredValue) > Number(res.yellowMin) && Number(res.measuredValue) <= Number(res.yellowMax)) {
                                res.thresholdBeach = 'Yes';
                                res.beachLevel = 'yellow';
                                if (state != 'Red') {
                                    setWOState('Yellow');
                                    state = 'Yellow';
                                    setLastInspectionValue(res.measuredValue);
                                }
                            } else if (Number(res.measuredValue) > Number(res.redMin) && Number(res.measuredValue) <= Number(res.redMax)) {
                                res.thresholdBeach = 'Yes';
                                res.beachLevel = 'Red';
                                state = 'Red';
                                setWOState('Red');
                                setLastInspectionValue(res.measuredValue);
                            } else {
                                verify = false;
                            }
                        } else {
                            res.thresholdBeach = 'No';
                            res.beachLevel = 'green';
                        }
                    }
                } else {
                    var s = res.measuredValue;
                    if (s.toLocaleLowerCase() === res.thresholdValue.toLocaleLowerCase()) {
                        res.thresholdBeach = 'No';
                        res.beachLevel = 'green';
                        setLastInspectionValue('Ok');
                    } else {
                        res.thresholdBeach = 'Yes';
                        res.beachLevel = 'red';
                        setWOState('Red');
                        setLastInspectionValue('Not-ok');
                    }
                }
            } else {
                verify = false;
            }

        })
        setInspectionList([...inspectionList]);
        if (!verify) {
            toast("Measured value is incorrect");
        }
        setVerify(verify)
    }

    function handlemeasuredValue(event, index) {
        let data = inspectionList;
        data[index]['measuredValue'] = event.target.value;
        setInspectionList([...data])
    }

    function handleRemarkValue(event, index) {
        let data = inspectionList;
        data[index]['remarks'] = event.target.value;
        setInspectionList([...data])
    }

    function handleSave(isWorkOrder) {
        if (assignee != null && assignee != '') {
            setIsUpdate(false);
            const workorder = props.open.workorder;
            var isSave = true;
            inspectionList.forEach(res => {
                isSave = res.thresholdBeach === 'Yes' ? (res.remarks == null || res.remarks == '') ? false : true : true;
            })
            if (isSave) {
                var data = { _id: workorder['_id'],assignee: assignee, inspectionList: inspectionList, state: 'Completed', isWorkOrder: isWorkOrder, inspectionState: wostate, lastinspectionvalue: lastinspectionvalue }
                apiService2('POST', myConfig.updateWOInspectionEP, data)
                    .then(response => {
                        setOpen(false);
                        setSubmitionError(false);
                        setSubmitionCompleted(false);
                        props.open.setfn();
                    })
                    .catch(error => {
                        console.error(error);
                    })
            } else {
                toast("Please enter the remarks");
            }
        } else {
            toast("Please select the assignee");
        }
    }

    function updateAssignee() {
        var data = { _id: workorder['_id'], assignee: assignee }
        apiService2('POST', myConfig.updateWOInspectionEP, data)
            .then(response => {
                setOpen(false);
                setSubmitionError(false);
                setSubmitionCompleted(false);
                props.open.setfn();
            })
            .catch(error => {
                console.error(error);
            })
    }
    function handleClose2() {
        setIsUpdate(false);
        setMaxWith('xs');
        setSubmitionCompleted(true);
        handleSave(false);
    }

    function handleSave2() {
        setIsUpdate(false);
        setSubmitionCompleted(true);
        handleSave(true);
    }

    function updateWorkorderMethod() {
        setSubmition(true)
        if (wostate == 'Red' || wostate == 'Yellow') {
            var workorder = workorderList.find(x => x.assetid == assetId && x.type == 'Corrective' && x.state != 'Completed');
            if (workorder) {
                if (wostate == 'Red') {
                    setErrorMessages('Corrective workorder already created for this asset id. Do you want to create breakdown workorder?');
                } else {
                    setErrorMessages('Corrective workorder already created for this asset id.');
                }
            } else {
                if (wostate == 'Red') {
                    setErrorMessages('Do you want to create Breakdown workorder?');
                } else {
                    setErrorMessages('Do you want to create Corrective workorder?');
                }
            }
            setMaxWith('xs');
            setIsUpdate(true);
        } else {
            handleSave(false);
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={maxWidth}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {!isSubmitionCompleted && !isUpdate && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Inspection From </DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6"></DialogContentText>
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-basic"
                                        label='Work Order Id'
                                        name='workorderid'
                                        value={woId}
                                        helperText=""
                                        margin="normal"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        sx={{ display: 'flex', width: "100%" }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-basic"
                                        label='Asset Id'
                                        name='assetid'
                                        value={assetId}
                                        helperText=""
                                        margin="normal"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        type="text"
                                        sx={{ display: 'flex', width: "100%" }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="From Date"
                                            value={fromDate}
                                            sx={{ width: '100%', marginTop: 2 }}
                                            onChange={(newValue) => setFromDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-basic"
                                        label='Assignee'
                                        name={'assignee'}
                                        value={assignee}
                                        onChange={e => {
                                            setAssignee(e.target.value)
                                        }}
                                        margin="normal"
                                        type="text"
                                        select
                                        defaultValue={''}
                                        sx={{ display: 'flex', width: "100%", }}>
                                        {userList.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ textAlign: "left" }}>
                                            <TableCell>Aspect</TableCell>
                                            <TableCell>Parameter</TableCell>
                                            <TableCell>Inspection Type</TableCell>
                                            <TableCell>Threshold Value</TableCell>
                                            <TableCell>Measured Value</TableCell>
                                            <TableCell>Threshold Beatch (Y/N)</TableCell>
                                            <TableCell>Breach Level (Red/Amber)</TableCell>
                                            <TableCell colSpan={2}>Remarks</TableCell>
                                            <TableCell>Measured by</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {inspectionList.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.aspect}
                                                </TableCell>
                                                <TableCell > {row.parameter}</TableCell>
                                                <TableCell > {row.inspectionType}</TableCell>
                                                <TableCell > {row.thresholdValue}</TableCell>
                                                <TableCell >
                                                    {
                                                        row.inspectionType === 'Measured' &&
                                                        <TextField
                                                            id="outlined-basic"
                                                            label=''
                                                            name='assetid'
                                                            helperText=""
                                                            value={row.measuredValue}
                                                            margin="normal"
                                                            onChange={(event) => handlemeasuredValue(event, index)}
                                                            type="text"
                                                            sx={{ display: 'flex', width: "50%" }} />
                                                    }
                                                    {
                                                        row.inspectionType === 'Visual' &&
                                                        <TextField
                                                            id="outlined-basic"
                                                            label=''
                                                            name='assetid'
                                                            onChange={(event) => handlemeasuredValue(event, index)}
                                                            margin="normal"
                                                            type="text"
                                                            select
                                                            defaultValue={''}
                                                            sx={{ display: 'flex', width: "100%" }}>
                                                            {enumList.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    }
                                                </TableCell>
                                                <TableCell > {row.thresholdBeach}</TableCell>
                                                <TableCell >
                                                    <Button type="button" variant="contained" color="primary" style={{ backgroundColor: row.beachLevel }}>
                                                        {row.beachLevel}
                                                    </Button>
                                                </TableCell>
                                                <TableCell colSpan={2} style={{ flex: 'd-flex' }} >
                                                    <TextField
                                                        id="outlined-basic"
                                                        label=''
                                                        name='assetid'
                                                        value={row.remarks}
                                                        helperText=""
                                                        margin="normal"
                                                        onChange={(event) => handleRemarkValue(event, index)}
                                                        type="text"
                                                        sx={{ display: 'flex', width: "100%" }} />
                                                </TableCell>
                                                <TableCell > {row.department}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <DialogActions>
                                <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                {
                                    !isVerify && <Button type="button" variant="contained" color="primary" onClick={handleVerifiy}>
                                        Verify
                                    </Button>
                                }
                                {
                                    isVerify && <Button type="button" variant="contained" color="primary" disabled={isSubmition} onClick={updateWorkorderMethod}>
                                        Submit
                                    </Button>
                                }
                                {
                                    workorder && (workorder.assignee == null || workorder.assignee == "") && <Button type="button" variant="contained" color="primary" onClick={updateAssignee}>Update </Button>
                                }
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title"></DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Inspection From updated Successfully</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                                    Back
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isSubmitionError && (
                    <React.Fragment
                    >
                        <DialogTitle id="form-dialog-title">Sorry! Inspection From Update Failed</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">Try Again Later</DialogContentText>
                            <DialogActions>
                                <Button type="button" variant="contained" color="secondary" onClick={handleRetry}>
                                    Back
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
                {isUpdate && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">Update Asset</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant="h6">{errorMessages}</DialogContentText>
                            <DialogActions >
                                <Button type="button" variant="contained" color="secondary" onClick={handleSave2}>
                                    Yes
                                </Button>
                                <Button type="button" variant="contained" color="secondary" onClick={handleClose2}>
                                    No
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </React.Fragment>
    );
}

export default InspectionForm;