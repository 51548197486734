import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";

import WorkIcon from '@mui/icons-material/Work';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import InventoryIcon from '@mui/icons-material/Inventory';
import GradingIcon from '@mui/icons-material/Grading';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FactoryIcon from '@mui/icons-material/Factory';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useSelector } from 'react-redux';
import { MENULIST } from "../../data/menu";
import e from "cors";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { user } = useSelector((state) => state.auth);
  const [menuList, setMenuList] = useState(getMenuAccess);

  function getMenuAccess() {
    if (user.access == 'ADMIN') return MENULIST;
    const view = user.accesslist?.filter((item) => item.isView === true);
    const viewList = view.map(e => e.id)
    console.log("1.getMenuAccess->", MENULIST, viewList);
    const returnList = MENULIST.filter((item) => viewList.includes(item.id) || (item.isTitle == true && item.id?.find((e) => viewList.includes(e))));
    console.log("2.getMenuAccess->", returnList);
    return returnList
  }

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          height: "100vh",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 15px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ARVAM EAM
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user ? user.firstname + " " + user.lastname : ""}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {user ? user.access : ""}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {user ? user.company : ""}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {
              menuList.map((item, index) => {
                return item.isTitle ? <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                  key={index}
                >
                  {item.title}
                </Typography> :
                  <Item
                    key={index}
                    title={item.title}
                    to={item.url}
                    icon={item.icon}
                    selected={selected}
                    setSelected={setSelected}
                  />
              })
            }
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
