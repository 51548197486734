/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
import { Grid, Box } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";
import { myConfig } from "../../settings.js";
import { apiService, apiService2 } from '../../features/apiService.js';
import { maintenanceModel } from "../../schema/schema.js";
import { toast } from 'react-toastify';
import { formCreator } from '../../features/helpers/formhelper';
import { isDateGreater } from '../../features/helpers/commonhelper.js';
let fakecount = 0

function AddMaintenance(props) {
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(props.open.state);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [isSubmitionError, setSubmitionError] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [asset, setAsset] = useState('');
  const [mainLibList, setMainLibList] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [formFields, setFormFields] = useState(maintenanceModel);
  const [errorMessage, setErrorMessage] = useState('');
  const [department, setDepartment] = useState('');
  const [isWarranty, setIsWarranty] = useState(false);
  const [values, setValues] = useState('');
  const [valueDB, setValueDB] = useState({ "asset": [], "Maintenance Library": [], "dept": [], frequency: [], safetyofficer: [], externalVendor: [] });

  // API End Point
  const endPoint = myConfig.addMaintenanceEP;

  function getInitialValues() {
    let initialValues = {};
    for (const field of formFields) {
      if (field.multiple) {
        initialValues[field.field] = [];
      } else {
        initialValues[field.field] = '';
      }
    }
    setInitialValues(initialValues);
    fakecount++;
    return initialValues;
  }

  if (props.open.state & !open) {
    setOpen(true);
    setIsWarranty(false);
    setFormFields([]);
    setFormFields(maintenanceModel);
    getInitialValues();
  }

  useEffect(() => {
    apiService(myConfig.getMainLibEP)
      .then(response => {
        setMainLibList(response.data);
        let modMainLibList = [];
        response.data?.forEach((item) => {
          if (item.state == 'ACTIVE') modMainLibList.push(item.name + "-" + item.code);
        })
        var db = valueDB;
        db["Maintenance Library"] = modMainLibList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  useEffect(() => {
    apiService2('POST',myConfig.getAssetsFilterEP)
      .then(response => {
        setAssetList(response.data);
        console.log('assetList', response.data);
        
        let modAssetList = [];
        response.data?.forEach((item) => {
          if (item.state === "Operational") modAssetList.push(item.name + "-" + item.assetid);
        })
        var db = valueDB;
        db["asset"] = modAssetList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  useEffect(() => {
    apiService(myConfig.getDeptEP)
      .then(response => {
        let modDeptList = [];
        for (const deptItem of response.data) {
          modDeptList.push(deptItem.name);
        }
        var db = valueDB;
        db["dept"] = modDeptList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  useEffect(() => {
    apiService2('POST', myConfig.getFacilitiesFilterEP, { isFilterSection: true },)
      .then(response => {
        var modSectionList = [];
        for (const fac of response.data?.filter(x => x.type == 'Section')) {
          modSectionList.push(fac.name);
        }
        var db = valueDB;
        db["section"] = modSectionList;
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);



  const [userList, setUserList] = useState([]);
  useEffect(() => {
    apiService(myConfig.getResource)
      .then(response => {
        setUserList(response.data)
        if (response.data.length == 0) {
          toast.error("User list Empty...");
        }
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  useEffect(() => {
    apiService2('POST', myConfig.getContractorFilter, { contractType: "Workshop Contractor" })
      .then(response => {
        var db = valueDB;
        db["externalVendor"] = response.data?.map(x => x.name)
        setValueDB(db);
      })
      .catch(error => {
        console.error(error);
      })
  }, [open]);

  // Handle States for the dialog box
  function handleClose() {
    setOpen(false);
    setSubmitionError(false);
    setSubmitionCompleted(false);
    props.open.setfn();
  }

  function handleSave() {
    createMaintance(values);
  }
  function createMaintance(value) {
    console.log('value', value);

    apiService2('POST', endPoint, value)
      .then(resp => {
        setSubmitionCompleted(true);
        setIsWarranty(false);
        if (resp.data == 'Maintenance Already Exists') {
          setErrorMessage('Maintenance Already Exists');
          setSubmitionError(true);
          setSubmitionCompleted(true);
        } else {
          setSubmitionError(false);
          setSubmitionCompleted(true);
        }
      })
      .catch(error => {
        setIsWarranty(false);
        setSubmitionError(true);
        setSubmitionCompleted(true);
      });
  }




  function handleSelect(field, value) {
    var data = [];
    if (field == "type") {
      if (value == "Preventive") {
        data = ["Meter", "Monthly", "BiWeekly"];
      } else {
        data = ["One Time"];
      }
      if (asset.assettype == 'Asset-T' && value == 'Breakdown') {
        let datas = [
          { field: "assetmovement", type: String, required: true, skip: false, label: 'Asset Movement', select: true, venum: ['Workshop', 'In-situ', 'External'], edit: true },
          { field: "externalVendor", type: String, required: true, skip: false, label: 'Contractor', select: true, venum: [], value: true, edit: true, condition: true, conditionField: 'assetmovement', conditionValue: 'External', },
        ];
        let s = [...formFields, ...datas];
        setFormFields(s);
      }
      valueDB["frequency"] = data;
    } else if (field == "frequency") {
      if (value == "Meter") {
        if (asset.reset) {
          if (formFields.find(x => x.field == "diffValue")) {
            formFields.pop();
            formFields.pop();
          }
          if (!formFields.find(x => x.field == "thresloadValue" && x.skip == false)) {
            let datas = [
              { field: "thresloadValue", type: Date, label: `Meter Threshold Value (${asset?.unit})`, skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter" },
              { field: "unit", type: String, label: 'Reset Meter', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter", defaultValue: asset?.unit, value: true }
            ];
            let s = [...formFields, ...datas];
            setFormFields(s);
            initialValues['unit'] = asset.unit;
            setInitialValues(initialValues);
          }
        } else {
          if (formFields.find(x => x.field == "thresloadValue")) {
            formFields.pop();
            formFields.pop();
          }
          if (!formFields.find(x => x.field == "diffValue" && x.skip == false)) {
            let datas = [
              { field: "diffValue", type: Date, label: `Meter Difference Value (${asset?.unit})`, skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter" },
              { field: "unit", type: String, label: 'Reset Meter', skip: false, condition: true, conditionField: 'frequency', conditionValue: "Meter", defaultValue: asset?.unit }
            ];
            let s = [...formFields, ...datas];
            setFormFields(s);
            initialValues['unit'] = asset.unit;
          }
        }
      }
    } else if (field == "assetid") {
      const asset = assetList.find(x => x.name + "-" + x.assetid == value);
      setAsset(asset);
    } else if (field == "dept") {
      setDepartment(value);
    } else if (field == "section") {
      const asset = assetList.filter(x => x.section == value);
      let modAssetList = [];
      asset?.forEach((item) => {
        if (item.state === "Operational") modAssetList.push(item.name + "-" + item.assetid);
      })
      valueDB["asset"] = modAssetList;
    }
  }

  const validationSchema = Yup.object().shape({
    mainlibcode: Yup.string().required('Maintenance Library is required'),
    assetid: Yup.string().required('Asset Id is required'),
    dept: Yup.string().required('Department is required'),
    approvalrequired: Yup.string().required('Approval Required is required'),
    safetyclearance: Yup.string().required('Safety Clearance is required'),
    type: Yup.string().required('Type is required'),
    frequency: Yup.string().required('Frequency is required'),
  })



  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        width="80vh"
      >
        {!isSubmitionCompleted && !isSubmitionError && !isWarranty && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Configure Maintenance</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6"></DialogContentText>
              <Formik
                key={fakecount}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  console.log('values', values);
                  if (values.frequency == 'Meter' && ((values.thresloadValue == '' || values.thresloadValue == undefined) && (values.diffValue == '' || values.diffValue == undefined))) {
                    setSubmitting(false);
                    toast.error("Meter is required");
                    return;
                  }
                  console.log('thresloadValue', values['thresloadValue']);

                  values['tenantid'] = user.tenantid;
                  const asset = assetList.find(x => x.name + "-" + x.assetid == values.assetid);
                  values["assetname"] = asset.name;
                  values["section"] = asset.section;
                  console.log(values);

                  values["subsection"] = asset.subsection;
                  values["assetid"] = asset.assetid;
                  if (values.frequency == 'Meter') {
                    values['unit'] = asset.unit;
                  }
                  const mainlib = mainLibList.find(x => x.name + "-" + x.code == values.mainlibcode);
                  values["mainlibname"] = mainlib.name;
                  values["mainlibcode"] = mainlib.code;
                  values['createddate'] = new Date();
                  setValues(values)
                  if (asset.warranty == 'Yes' && values.type == 'Breakdown' && isDateGreater(asset.warrantydate, new Date())) {
                    setIsWarranty(true);
                    setSubmitting(true)
                  } else {
                    createMaintance(values);
                  }
                }}
                // Need to expand the validation schema more later
                validationSchema={validationSchema} >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    resetForm
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box >
                        <Grid container spacing={2}>
                          {formCreator("Complex", 'add', formFields, initialValues, values, handleChange, handleBlur, touched, errors, valueDB, handleSelect, false, setFieldValue, 6)}
                        </Grid>
                      </Box>
                      <DialogActions>
                        <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button type="button" variant="contained" color="primary"
                          onClick={() => resetForm({ values: setInitialValues(getInitialValues()) })}
                          disabled={!dirty || isSubmitting}
                        >
                          Reset
                        </Button>
                        <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting} onClick={handleSubmit}>
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  );
                }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionCompleted && !isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">Maintenance Configured</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isSubmitionError && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Sorry!  Maintenance Config Failed</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">{errorMessage}</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
        {isWarranty && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Warranty is available. Do you want to create a work order?</DialogTitle>
            <DialogContent>
              <DialogContentText variant="h6">{errorMessage}</DialogContentText>
              <DialogActions>
                <Button type="button" variant="contained" color="secondary" onClick={handleSave}>
                  Yes
                </Button>
                <Button type="button" variant="contained" color="secondary" onClick={handleClose}>
                  No
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default AddMaintenance;